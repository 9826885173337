import { useState, useMemo, useEffect, useRef } from 'react';
import { createConsumer } from '@rails/actioncable';

import moment from 'moment';
import { useGetMultiselectOptions } from '../../hooks';
import { downloadReport } from '../../API/mutations';

export const useReports = ({ initialData }) => {
  const [period, setPeriod] = useState({
    startDate: moment(initialData.period[0]),
    endDate: moment(initialData.period[1]),
  });
  const [currentProjects, setCurrentProjects] = useState(null);
  const [currentSites, setCurrentSites] = useState(null);
  const [format, setFormat] = useState({ value: 'singlesheet', label: 'Single Sheet' });
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState(initialData.reports);
  const [generatedReport, setGeneratedReport] = useState(null);

  const projectOptions = useGetMultiselectOptions(initialData.availableProjects);
  const siteOptions = useGetMultiselectOptions(initialData.availableSites);

  const projectIds = useMemo(
    () => (currentProjects ? currentProjects.map((project) => project.id) : []),
    [currentProjects],
  );
  const siteIds = useMemo(
    () => (currentSites ? currentSites.map((site) => site.id) : []),
    [currentSites],
  );

  const wsConsumer = useRef(null);
  const wsChannel = useRef(null);

  useEffect(() => {
    wsConsumer.current = createConsumer();

    return () => {
      wsConsumer.current.disconnect();
    };
  }, []);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const { data: report } = await downloadReport({
        projectIds,
        siteIds,
        period: [period.startDate, period.endDate],
        multiplesheets: format.value === 'multisheet',
      });
      if (report.id) {
        setGeneratedReport(report);
        setReports((prevReports) => [report, ...prevReports]);
        wsChannel.current =
          wsConsumer.current &&
          wsConsumer.current.subscriptions.create(
            {
              channel: 'ReportsChannel',
              id: report.id,
            },
            {
              received: (receivedReport) => {
                setGeneratedReport(receivedReport);
                setReports((prevReports) =>
                  prevReports.map((stateReport) =>
                    stateReport.id === receivedReport.id ? receivedReport : stateReport,
                  ),
                );
                if (receivedReport.status === 'ready' || receivedReport.status === 'error') {
                  setIsLoading(false);
                }
              },
            },
          );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wsChannel.current) {
      const unfinishedReports = reports.filter(
        (report) => report.status === 'waiting' || report.status === 'processing',
      );
      if (unfinishedReports.length > 0) {
        unfinishedReports.forEach((report) => {
          wsChannel.current =
            wsConsumer.current &&
            wsConsumer.current.subscriptions.create(
              {
                channel: 'ReportsChannel',
                id: report.id,
              },
              {
                received: (receivedReport) => {
                  setReports((prevReports) =>
                    prevReports.map((stateReport) =>
                      stateReport.id === receivedReport.id ? receivedReport : stateReport,
                    ),
                  );
                  if (receivedReport.status === 'ready' || receivedReport.status === 'error') {
                    setIsLoading(false);
                  }
                },
              },
            );
        });
      }
    }
  }, []);

  return {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    format,
    setFormat,
    period,
    setPeriod,
    projectOptions,
    siteOptions,
    handleClick,
    isLoading,
    generatedReport,
    reports,
  };
};
