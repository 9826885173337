import React from 'react';

import { Button } from '../../atoms';
import { initialReportsDataType } from './types';
import { useReports } from './useReports';
import { ReportsList } from './ReportsList';
import { Filters } from './Filters';
import styles from './Reports.module.scss';
import { ReportStatus } from './ReportStatus';

const Reports = ({ initialData }) => {
  const {
    currentProjects,
    setCurrentProjects,
    currentSites,
    setCurrentSites,
    format,
    setFormat,
    period,
    setPeriod,
    projectOptions,
    siteOptions,
    handleClick,
    isLoading,
    generatedReport,
    reports,
  } = useReports({ initialData });

  return (
    <div className={styles.page}>
      <div className={styles.box}>
        <div className={styles.grid}>
          <h6 className={`mb-3 ${styles.title}`}>Generate an Incursion Report</h6>
          <Filters
            currentProjects={currentProjects}
            setCurrentProjects={setCurrentProjects}
            currentSites={currentSites}
            setCurrentSites={setCurrentSites}
            format={format}
            setFormat={setFormat}
            period={period}
            setPeriod={setPeriod}
            projectOptions={projectOptions}
            siteOptions={siteOptions}
            dateFormat={initialData.dateFormat}
          />
          <div>
            <Button
              disabled={isLoading}
              kind="success"
              onClick={handleClick}
              className={styles.button}
            >
              Generate Report
            </Button>
          </div>
        </div>
        {generatedReport && <ReportStatus generatedReport={generatedReport} />}
        {reports.length > 0 && (
          <ReportsList reports={reports} dateFormat={initialData.dateFormat} />
        )}
      </div>
    </div>
  );
};

export default Reports;

Reports.propTypes = {
  initialData: initialReportsDataType,
};
