import React from 'react';
import { func, arrayOf, shape, string } from 'prop-types';

import {
  changeCurrentItemsFormat,
  changeSelectedItemsFormat,
} from '../../../atoms/MultiSelect/utils';
import { dateFormatType, projectType } from '../../../API/types';
import { periodType } from '../../../atoms/DatePicker/types';
import { DrawerWithDatePicker } from '../../../atoms/Drawer/components/DrawerWithDatePicker/DrawerWithDatePicker';
import { MultiSelect } from '../../../atoms';
import { optionsType } from '../../../atoms/Filters/types';
import styles from './Filters.module.scss';

const formatOptions = [
  {
    value: 'singlesheet',
    label: 'Single Sheet',
  },
  { value: 'multisheet', label: 'Multisheet' },
];

export const Filters = ({
  currentProjects,
  setCurrentProjects,
  currentSites,
  setCurrentSites,
  format,
  setFormat,
  period,
  setPeriod,
  projectOptions,
  siteOptions,
  dateFormat,
}) => {
  return (
    <>
      <DrawerWithDatePicker
        period={period}
        setPeriod={setPeriod}
        dateFormat={dateFormat}
        wrapperClassName={styles.dateWrapper}
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentProjects)}
        initialValues={changeCurrentItemsFormat(currentProjects)}
        options={projectOptions}
        placeholder="All"
        onChange={(selectedValues) => setCurrentProjects(changeSelectedItemsFormat(selectedValues))}
        label="Projects"
        wrapperClassName={styles.projects}
      />
      <MultiSelect
        currentValues={changeCurrentItemsFormat(currentSites)}
        initialValues={changeCurrentItemsFormat(currentSites)}
        options={siteOptions}
        placeholder="All"
        onChange={(selectedValues) => setCurrentSites(changeSelectedItemsFormat(selectedValues))}
        label="Sites"
        wrapperClassName={styles.sites}
      />
      <MultiSelect
        currentValues={format}
        initialValues={format}
        options={formatOptions}
        configOptions={{
          isMulti: false,
          closeMenuOnSelect: true,
          isSearchable: true,
        }}
        placeholder="Format"
        label="Format"
        onChange={(selectedValue) => setFormat(selectedValue)}
      />
    </>
  );
};

Filters.propTypes = {
  currentProjects: arrayOf(projectType),
  setCurrentProjects: func,
  currentSites: arrayOf(projectType),
  setCurrentSites: func,
  format: shape({
    value: string,
    label: string,
  }),
  setFormat: func,
  period: periodType,
  setPeriod: func,
  projectOptions: optionsType,
  siteOptions: optionsType,
  dateFormat: dateFormatType,
};
