import React, { useEffect } from 'react';
import { oneOf, func, string } from 'prop-types';

import { Button } from 'atoms/Button/Button';

import styles from './Tabs.module.scss';

const tabs = [
  { text: 'Proximity Warning' },
  { text: 'SCS Insights' },
  // { text: 'Machine Insights' },
];

export const Tabs = ({ activeTab, setActiveTab, dvrId }) => {
  useEffect(() => {
    if (dvrId) {
      tabs.splice(1, 0, { text: 'Vision 360' });
    }

    return () => {
      tabs.splice(1, 1);
    };
  }, [dvrId, tabs]);

  return (
    <div className={styles.wrapper}>
      {tabs.map((tab) => (
        <Button
          key={tab.text}
          kind="dark-1"
          onClick={() => setActiveTab(tab.text)}
          className={tab.text === activeTab ? styles.active : ''}
        >
          {tab.text}
        </Button>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  activeTab: oneOf(['Proximity Warning', 'Vision 360', 'SCS Insights', 'Machine Insights']),
  setActiveTab: func,
  dvrId: string,
};
