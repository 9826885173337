import React, { useState } from 'react';
import moment from 'moment';
import { bool, func, string } from 'prop-types';

import { dateFormatType } from 'API/types';
import { Button, MultiSelect, Input } from 'atoms';
import styles from '../Videos.module.scss';
import { DrawerWithSingleDateAndTimePicker } from 'atoms/Drawer/components/DrawerWithSingleDateAndTimePicker/DrawerWithSingleDateAndTimePicker';

import localStyles from './RequestVideoForm.module.scss';
import { OnlineStatus } from './OnlineStatus/OnlineStatus';

const options = [
  { value: '30', label: '30 seconds clip' },
  { value: '60', label: '1 minute clip' },
  { value: '180', label: '3 minutes clip' },
  { value: '300', label: '5 minutes clip' },
];

export const RequestVideoForm = ({
  dateFormat,
  requestVideo,
  isRequestVideoLoading,
  requestVideoError,
  online,
}) => {
  const [timestamp, setTimestamp] = useState({ date: moment() });
  const [duration, setDuration] = useState(options[0]);
  const [description, setDescription] = useState('');

  const handleClick = () => {
    requestVideo(duration.value, timestamp.date, description);
  };

  return (
    <div className={`card mb-3 position-relative ${styles.wrapper}`}>
      <div className={localStyles.header}>
        <h4 className={localStyles.title}>Request a Video Clip</h4>
        <OnlineStatus online={online} />
      </div>
      <div className={localStyles.formWrapper}>
        <DrawerWithSingleDateAndTimePicker
          period={timestamp}
          setPeriod={setTimestamp}
          dateFormat={dateFormat}
        />
        <MultiSelect
          currentValues={duration}
          initialValues={duration}
          options={options}
          onChange={(selectedValues) => setDuration(selectedValues)}
          wrapperClassName={localStyles.multiSelect}
          configOptions={{
            isMulti: false,
            closeMenuOnSelect: true,
            isSearchable: true,
          }}
        />
        <Input placeholder="Description" value={description} onChange={setDescription} />
        <Button kind="dark-1" onClick={handleClick} disabled={isRequestVideoLoading}>
          Request Video
        </Button>
      </div>
      {requestVideoError && (
        <div className={localStyles.error}>Video for provided date already requested!</div>
      )}
    </div>
  );
};

RequestVideoForm.propTypes = {
  dateFormat: dateFormatType,
  requestVideo: func,
  isRequestVideoLoading: bool,
  requestVideoError: string,
  online: bool,
};
