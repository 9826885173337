import React, { useMemo, useState, useCallback } from 'react';
import { string, number, oneOfType } from 'prop-types';
import moment from 'moment';

import { TableControlledPagination } from 'atoms/TableSZ/TableControlledPagination/TableControlledPagination';
import { DarkCard } from '../DarkCard/DarkCard';
import { useFetchMessages } from 'API/hooks/useFetchMessages/useFetchMessages';

import { InfoCell } from './InfoCell/InfoCell';
import { DateCell } from './DateCell/DateCell';
import { DataTypeCell } from './DataTypeCell/DataTypeCell';
import { getMessage } from './getMessage/getMessage';
import { SCSInsightsTableSkeleton } from './SCSInsightsTableSkeleton/SCSInsightsTableSkeleton';

const PER_PAGE = [25, 50, 100];

export const SCSInsightsTable = ({ dateFormat, baseStationId }) => {
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    perPage: PER_PAGE[0],
  });
  const [sortingParams, setSortingParams] = useState({
    sort: null,
    sortBy: null,
  });

  const handleTableHeaderClick = useCallback((clickedColumnHeader) => {
    setSortingParams(({ sort, sortBy }) => {
      if (sortBy === clickedColumnHeader) {
        if (sort === 'asc') {
          return { sort: 'desc', sortBy };
        }
        return { sort: null, sortBy: null };
      }
      return { sort: 'asc', sortBy: clickedColumnHeader };
    });
  }, []);

  const { data, isLoading, error } = useFetchMessages({
    shouldFetch: true,
    baseStationId,
    page: paginationParams.currentPage,
    per: paginationParams.perPage,
    ...sortingParams,
  });

  const formattedData = useMemo(
    () =>
      data
        ? data.allMessages.map((item) => {
            const parsedDate = moment(item.timestamp).format(`${dateFormat} [@] HH:mm Z`);
            const parsedMessage = getMessage(item.code, item.status);

            return {
              ...item,
              timestamp: parsedDate,
              code: parsedMessage,
            };
          })
        : [],
    [data, dateFormat],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'DATE & TIME',
        accessor: 'timestamp',
        Cell: DateCell,
      },
      {
        Header: 'DATA TYPE',
        accessor: 'status',
        Cell: DataTypeCell,
      },
      {
        Header: 'INFO',
        accessor: 'code',
        Cell: InfoCell,
      },
    ],
    [],
  );

  if (isLoading) {
    return <SCSInsightsTableSkeleton />;
  }

  if (error) {
    return (
      <DarkCard>
        <p className="text-danger m-0">Error! Something went wrong. Try again later</p>
      </DarkCard>
    );
  }

  return (
    <DarkCard>
      <TableControlledPagination
        columns={columns}
        data={formattedData}
        initialPaginationState={paginationParams}
        setPaginationParams={setPaginationParams}
        pageCount={data.pagination.totalPages}
        totalCount={data.pagination.totalCount}
        pageSizeOptions={PER_PAGE}
        sortingParams={sortingParams}
        handleTableHeaderClick={handleTableHeaderClick}
        isDarkMode
      />
    </DarkCard>
  );
};

SCSInsightsTable.propTypes = {
  dateFormat: string,
  baseStationId: oneOfType([string, number]),
};
