import { node } from 'prop-types';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const SpinnerPortal = ({ children }) => {
  const portal = useRef(document.createElement('div'));

  useEffect(() => {
    const nav = document.getElementById('mainNav');
    if (portal.current && nav) {
      portal.current.id = 'nav-spinner';
      portal.current.classList.add('mx-3');
      nav.insertBefore(portal.current, nav.childNodes[2]);
    }

    return () => nav.removeChild(portal.current);
  }, []);

  return createPortal(children, portal.current);
};

SpinnerPortal.propTypes = {
  children: node,
};
