import React from 'react';
import { arrayOf, shape, number, string, oneOfType, node } from 'prop-types';

import { BasicInfoCard } from 'atoms';
import styles from './BasicCards.module.scss';

export const BasicCards = ({ title, data, children }) => {
  return (
    <div className="pb-4">
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.cardsInfoWrapper}>
        {data.map(item => (
          <BasicInfoCard
            key={item.key}
            title={item.title}
            count={item.count}
            countUnit={item.countUnit ? item.countUnit : ''}
          />
        ))}
        {children && children}
      </div>
    </div>
  );
};

BasicCards.propTypes = {
  title: string,
  data: arrayOf(
    shape({
      key: number.isRequired,
      title: string.isRequired,
      count: oneOfType([string, number]).isRequired,
      countUnit: string,
    }),
  ),
  children: node,
};
